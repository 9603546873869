/* global axios */

class FlowsUbluxAPI {
  // eslint-disable-next-line class-methods-use-this
  get url() {
    return process.env.VUE_APP_API_WA;
  }

  getFlows(wa_id) {
    return axios.ublux.get(
      `${this.url}/was/admin/provider/clients/flows/${wa_id}`
    );
  }

  sendFlowAutomation(data) {
    return axios.ublux.post(`${this.url}/cw-adapter/wa-flows`, data);
  }

  updateFlowAutomation(id, data) {
    return axios.ublux.patch(`${this.url}/cw-adapter/wa-flows/${id}`, data);
  }

  deleteFlowAutomation(id) {
    return axios.ublux.delete(`${this.url}/cw-adapter/wa-flows/${id}`);
  }

  getFlowAutomation(id) {
    return axios.ublux.get(`${this.url}/cw-adapter/wa-flows/${id}`);
  }
}

export default new FlowsUbluxAPI();
