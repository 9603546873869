import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import { uploadFile } from 'dashboard/helper/uploadHelper';
import AutomationAPI from '../../api/automation';
import FlowsUbluxAPI from '../../api/ublux/flowsUblux';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
  },
};

export const getters = {
  getAutomations(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

export const actions = {
  get: async function getAutomations({ commit }) {
    commit(types.SET_AUTOMATION_UI_FLAG, { isFetching: true });
    try {
      const response = await AutomationAPI.get();
      commit(types.SET_AUTOMATIONS, response.data.payload);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_AUTOMATION_UI_FLAG, { isFetching: false });
    }
  },
  create: async function createAutomation({ commit }, automationObj) {
    commit(types.SET_AUTOMATION_UI_FLAG, { isCreating: true });

    if (!automationObj.actions || automationObj.actions.length === 0) {
      commit(types.SET_AUTOMATION_UI_FLAG, { isCreating: false });
      throw new Error();
    }

    try {
      let adapterFlowId = [];
      await Promise.all(
        automationObj.actions.map(async element => {
          const isFlow = element.action_params.find(
            item => typeof item === 'object' && item.type === 'send_flow'
          );

          if (isFlow) {
            const flow = Object.assign(
              {},
              ...element.action_params.filter(item => typeof item === 'object')
            );

            const responseUblux = await FlowsUbluxAPI.sendFlowAutomation({
              wa_id: flow.phoneFrom,
              flow_id: flow.flow.id,
              automation_rule_id: '',
              emails: flow.emails.filter(
                item => item !== null && item !== undefined && item !== ''
              ),
              webhook: flow.webhook,
            });

            if (
              !responseUblux.data ||
              (responseUblux.data?.data &&
                Object.values(responseUblux.data?.data).length < 0)
            ) {
              throw new Error('La respuesta de Ublux está vacía.');
            }

            const urlIndex = element.action_params.findIndex(
              item =>
                typeof item === 'string' &&
                item.includes('cw-adapter/wa-flows/send/')
            );

            const adapterIndex = element.action_params.findIndex(
              item => typeof item === 'object' && 'adapterFlowId' in item
            );

            adapterFlowId.push(responseUblux.data.data.id);
            element.action_params[adapterIndex].adapterFlowId =
              responseUblux.data.data.id;
            element.action_params[
              urlIndex
            ] = `${responseUblux.data.data.url}${responseUblux.data.data.id}`;
          }
        })
      );

      const response = await AutomationAPI.create(automationObj);

      if (adapterFlowId && adapterFlowId.length > 0) {
        adapterFlowId.forEach(async id => {
          await FlowsUbluxAPI.updateFlowAutomation(id, {
            automation_rule_id: response.data.id,
          });
        });
      }

      commit(types.ADD_AUTOMATION, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_AUTOMATION_UI_FLAG, { isCreating: false });
    }
  },
  patch: async ({ commit }, updateObj) => {
    commit(types.SET_AUTOMATION_UI_FLAG, { isUpdating: true });
    try {
      let adapterFlowId = [];
      await Promise.all(
        updateObj.actions.map(async element => {
          const isFlow = element.action_params.find(
            item => typeof item === 'object' && item.type === 'send_flow'
          );

          if (isFlow) {
            const flow = Object.assign(
              {},
              ...element.action_params.filter(item => typeof item === 'object')
            );

            if (!flow.adapterFlowId) {
              const responseUblux = await FlowsUbluxAPI.sendFlowAutomation({
                wa_id: flow.phoneFrom,
                flow_id: flow.flow.id,
                automation_rule_id: '',
                emails: flow.emails.filter(
                  item => item !== null && item !== undefined && item !== ''
                ),
                webhook: flow.webhook,
              });

              if (
                !responseUblux.data ||
                (responseUblux.data?.data &&
                  Object.values(responseUblux.data?.data).length < 0)
              ) {
                throw new Error('La respuesta de Ublux está vacía.');
              }

              const urlIndex = element.action_params.findIndex(
                item =>
                  typeof item === 'string' &&
                  item.includes('cw-adapter/wa-flows/send/')
              );

              const adapterIndex = element.action_params.findIndex(
                item => typeof item === 'object' && 'adapterFlowId' in item
              );

              adapterFlowId.push(responseUblux.data.data.id);
              element.action_params[adapterIndex].adapterFlowId =
                responseUblux.data.data.id;
              element.action_params[
                urlIndex
              ] = `${responseUblux.data.data.url}${responseUblux.data.data.id}`;
            } else {
              await FlowsUbluxAPI.updateFlowAutomation(flow.adapterFlowId, {
                wa_id: flow.phoneFrom,
                flow_id: flow.flow.id,
                automation_rule_id: updateObj.id,
                emails: flow.emails.filter(
                  item => item !== null && item !== undefined && item !== ''
                ),
                webhook: flow.webhook,
              });
            }
          }
        })
      );

      const response = await AutomationAPI.update(updateObj.id, updateObj);

      if (adapterFlowId && adapterFlowId.length > 0) {
        adapterFlowId.forEach(async flowId => {
          await FlowsUbluxAPI.updateFlowAutomation(flowId, {
            automation_rule_id: response.data.payload.id,
          });
        });
      }

      commit(types.EDIT_AUTOMATION, response.data.payload);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_AUTOMATION_UI_FLAG, { isUpdating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_AUTOMATION_UI_FLAG, { isUpdating: true });
    try {
      const response = await AutomationAPI.update(id, updateObj);
      commit(types.EDIT_AUTOMATION, response.data.payload);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_AUTOMATION_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_AUTOMATION_UI_FLAG, { isDeleting: true });
    try {
      await AutomationAPI.delete(id);
      commit(types.DELETE_AUTOMATION, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_AUTOMATION_UI_FLAG, { isDeleting: false });
    }
  },
  clone: async ({ commit }, id) => {
    commit(types.SET_AUTOMATION_UI_FLAG, { isCloning: true });
    try {
      await AutomationAPI.clone(id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_AUTOMATION_UI_FLAG, { isCloning: false });
    }
  },
  uploadAttachment: async (_, file) => {
    const { blobId } = await uploadFile(file);
    return blobId;
  },
};

export const mutations = {
  [types.SET_AUTOMATION_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.ADD_AUTOMATION]: MutationHelpers.create,
  [types.SET_AUTOMATIONS]: MutationHelpers.set,
  [types.EDIT_AUTOMATION]: MutationHelpers.update,
  [types.DELETE_AUTOMATION]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
