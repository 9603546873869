/* global axios */
import Cookies from 'js-cookie';

class HubspotUbluxAPI {
  // eslint-disable-next-line class-methods-use-this
  get url() {
    return process.env.VUE_APP_API_WA;
  }

  // eslint-disable-next-line class-methods-use-this
  get token() {
    const sessionInfo = Cookies.get('cw_d_session_info');
    const sessionInfoObject = JSON.parse(sessionInfo);

    return sessionInfoObject.authorization;
  }

  get httpOptions() {
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.token,
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getAccount(inbox_id) {
    return axios.chatwoot.get(
      `${this.url}/cw-adapter/hubspot/get-account/${inbox_id}`,
      this.httpOptions
    );
  }
}

export default new HubspotUbluxAPI();
